import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

const teamMembers = [
    {
        img: "/images/wiktoria.png",
        url: "/o-nas/nasz-zespol/wiktoria-ralowska-gmoch",
        name: "Wiktoria Rałowska-Gmoch",
        specialization: "Specjalista Neurolog",
        desc: "Członek Polskiego Towarzystwa Bólów Głowy, członek Polskiego Towarzystwa Neurofizjologii Klinicznej, zastosowanie toksyny botulinowej w neurologii (migrena przewlekła, dystonie ogniskowe, spastyczność), badania elektroneuromiograficzne, kompleksowa diagnostyka i leczenie schorzeń neurologicznych."
    },
    {
        img: "/images/anna.jpg",
        url: "/o-nas/nasz-zespol/dr-anna-rutkowska",
        name: "Dr Anna Rutkowska",
        specialization: "Specjalista Fizjoterapii",
        desc: "Absolwentka fizjoterapii na Politechnice Opolskiej. Od 2011 roku pracownik naukowo-dydaktyczny na kierunku fizjoterapia na Politechnice Opolskiej. W 2016 roku uzyskała tytuł doktora nauk o zdrowiu na Śląskim Uniwersytecie Medycznym. Autorka licznych publikacji naukowych o zasięgu międzynarodowym, kierownik grantów badawczych, członek międzynarodowych zespołów naukowych. "
    },
    {
        img: "/images/sebastian.png",
        url: "/o-nas/nasz-zespol/dr-sebastian-rutkowski",
        name: "Dr Sebastian Rutkowski",
        specialization: "Specjalista Fizjoterapii",
        desc: "Fizjoterapeuta, doktor nauk o kulturze fizycznej, nauczyciel akademicki oraz naukowiec. Odbył liczne szkolenia w zakresie fizjoterapii neurologicznej oraz terapii manualnej, student ostatniego roku osteopatii w Akademii Osteopatii. W swojej działalności terapeutycznej łączy podejście fizjoterapeutyczne, osteopatyczne z wykorzystaniem mikrokinezyterapii. To połączenie przynosi bardzo dobre efekty u pacjentów z dolegliwościami bólowymi, problemami dobrostanu psychicznego/emocjonalnego jak u pacjentów niezdiagnozowanych z dolegliwościami rzadkimi. Jego filozofia pracy z pacjentami to holistyczne podejście, mające na celu ponowne uruchomienie mechanizmów naprawczych, zwanych inaczej potencjałem zdrowia."
    },
    {
        img: "/images/kamil2.png",
        url: "/o-nas/nasz-zespol/mgr-kamil-kornberg",
        name: "Mgr Kamil Kornberg",
        specialization: "Specjalista Fizjoterapii",
        desc: "Jestem absolwentem studiów magisterskich na kierunku fizjoterapia na Politechnice Opolskiej. W pracy cenię sobie kontakt z ludźmi oraz to, że mogę im pomagać i brać udział w procesie ich powrotu do zdrowia. W fizjoterapii podoba mi się możliwość ciągłego rozwoju i związku z tym biorę udział w wielu szkoleniach. Od zawsze fascynowała mnie złożoność ludzkiego ciała. W wolnych chwilach lubię czytać książki i zobaczyć dobry film, lecz również spędzić aktywnie czas na jeździe na rowerze lub siłowni. Moją pasją są podróże oraz gotowanie."
    },
    {
        img: "/images/henryk2.png",
        url: "/o-nas/nasz-zespol/dr-henryk-racheniuk",
        name: "Dr Henryk Racheniuk",
        specialization: "Specjalista Fizjoterapii",
        desc: "Fizjoterapeuta z ponad 16 - letnim doświadczeniem. Absolwent Akademii Wychowania Fizycznego we Wrocławiu kierunek Fizjoterapia, Akademii medycznej Kierunku Zdrowie Publiczne, Politechniki Opolskiej studiów podyplomowych z zakresu Pedagogiki oraz Osteopathie Schule Deutschland kierunek Osteopatia. Ukończył liczne certyfikowane szkolenia z rehabilitacji i ortopedycznej, osteopatycznej i funkcjonalnej oraz mikrokinezyterapii. Od 15 lat prowadzi własną praktykę fizjoterapeutyczną oraz jest wykładowcą w Instytucie Fizjoterapii Politechniki Opolskiej. W 2015 uzyskał stopień Specjalisty Fizjoterapii, w 2018 uzyskał stopień doktora."
    },
];

const getElements = () => {
    const elements = teamMembers.concat(teamMembers);
    return elements.map((element, i) => {
        return { ...element, id: i + 1 };
    });
};

const getElementWidth = () => {
    if (window.matchMedia("(max-width: 429px)").matches) {
        return 240;
    }
    if (window.matchMedia("(min-width: 430px) and (max-width: 767px)").matches) {
        return 300;
    }
    if (window.matchMedia("(min-width: 768px)").matches) {
        return 450;
    }
};

const Team = () => {
    const [elements, setElements] = useState(getElements());
    const [padding, setPadding] = useState(getElementWidth());
    const selected = 4;

    useEffect(() => {
        window
            .matchMedia("(max-width: 429px)")
            .addEventListener('change', e => {
                if (e.matches)
                    setPadding(240);
            });
        window
            .matchMedia("(min-width: 430px) and (max-width: 767px)")
            .addEventListener('change', e => {
                if (e.matches)
                    setPadding(300);
            });
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => {
                if (e.matches)
                    setPadding(450);
            });
    }, []);

    const handleLeft = () => {
        setElements(prev => {
            const el = prev.pop();
            return [el, ...prev];
        });
    }

    const handleRight = () => {
        setElements(prev => {
            const el = prev.shift();
            return [...prev, el];
        });
    }

    return (
        <>
            <img src="/images/team_arrow.png" className="team__arrow team__arrow--left" onClick={handleLeft} />
            <img src="/images/team_arrow.png" className="team__arrow team__arrow--right" onClick={handleRight} />
            <div className="team__elements" style={{ width: `${(elements.length + 1) * padding}px`, paddingLeft: `${padding}px` }}>
                {elements.map((member, i) =>
                    <div key={member.id} className={`team__element ${selected === i ? "active" : ""}`}>
                        <img src={member.img} alt={member.name} className="team__image" />
                        <div className="team__name">{member.name}</div>
                        <div className="team__specialization">{member.specialization}</div>
                        <div className="team__text">{member.desc}</div>
                        <div className="team__button-box">
                            <a href={member.url} className="button button--green team__button">Przeczytaj więcej</a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

const root = createRoot(document.getElementById('team'));
root.render(<Team />);
